<template>
  <v-card>
    <v-card-title>
      <DefaultModal
        @closedModal="clearForm"
        @formSubmitted="addToBlacklist"
        ref="modal"
        activator-name="Dodaj"
        title="Dodawanie do czarnej listy"
      >
        <v-text-field label="Słowo kluczowe" v-model="form.keyword" outlined dense hide-details />
        <v-switch v-model="form.blackListedConstantly" label="Dodaj na stałe" />
        <DatePickerInput
          label="Ważność do"
          readonly
          v-model="form.toDate"
          v-if="!form.blackListedConstantly"
        />
      </DefaultModal>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Wyszukaj"
        class="shrink"
        single-line
        hide-details
        @keyup.enter="fetchItems"
        @blur="fetchItems"
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="$data.$_paginationMixin_options"
      item-key="id"
      :loading="$data.$_paginationMixin_fetchingItems"
      :search="search"
      :items-per-page="$data.$_paginationMixin_itemsPerPage"
      :page="1"
      :server-items-length="$data.$_paginationMixin_totalCount"
      :footer-props="{
        'items-per-page-options': $data.$_paginationMixin_rowsPerPageOptions,
      }"
    >
      <template v-slot:[`item.toDate`]="{item}">
        {{ item.toDate | date }}
      </template>
      <template v-slot:[`item.delete`]="{item}">
        <v-icon @click="removeFromBL(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
    <Confirm ref="modalConfirm" />
  </v-card>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import paginationMixin from '@/mixins/pagination.mixin.js'
import DefaultModal from '@/components/Modals/DefaultModal'
import DatePickerInput from '@/components/UI/DatePickerInput'
import RepositoryFactory from '@/repositories/RepositoryFactory'
import Confirm from '@/components/UI/Confirm'

const BlacklistRepository = RepositoryFactory.get('blackList')

export default {
  components: {
    DatePickerInput,
    DefaultModal,
    Confirm,
  },
  mixins: [paginationMixin],
  data() {
    return {
      search: '',
      loading: false,
      headers: [
        {text: 'Słowo kluczowe', value: 'keyword'},
        {text: 'Ważne do', value: 'toDate'},
        {text: 'Usuń', value: 'delete', width: '7%', sortable: false},
      ],
      form: {
        keyword: null,
        blackListedConstantly: false,
        toDate: new Date().toISOString().slice(0, 10),
      },
    }
  },
  watch: {
    searchOptions: {
      handler() {
        this.fetchItems()
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(['getKeywordBlackList', 'showNotification']),
    async fetchItems() {
      const {search} = this
      this.$data.$_paginationMixin_fetchingItems = true
      const query = this.$_paginationMixin_createQuery({
        ...this.$data.$_paginationMixin_options,
        search,
      })
      this.$data.$_paginationMixin_totalCount = await this.getKeywordBlackList({
        ...query,
      })
      this.$data.$_paginationMixin_fetchingItems = false
    },
    clearForm() {
      this.form.keyword = null
      this.form.blackListedConstantly = false
      this.form.toDate = new Date().toISOString().slice(0, 10)
    },
    async addToBlacklist() {
      try {
        await BlacklistRepository.addToBlackList('keyword', {
          ...this.form,
          toDate: this.form.blackListedConstantly ? '0001-01-01' : this.form.toDate,
        })
        await this.fetchItems()
        this.showNotification({
          message: 'NOTY_BLACKLIST_KEYWORD_ADDED',
          type: 'success',
        })
        this.$refs.modal.close()
        this.form.blackListedConstantly = false
      } catch (e) {
        this.showNotification({
          message: e,
          type: 'error',
        })
      }
    },
    async removeFromBL(item) {
      const {keyword, id} = item
      const res = await this.$refs.modalConfirm.open({
        title: 'Usuwanie słowa kluczowego',
        message: `Czy na pewno chcesz usunąć słowo "${keyword}" z czarnej listy?`,
      })
      if (res) {
        try {
          await BlacklistRepository.removeFromBlackList('keyword', {
            keywordId: id,
          })
          await this.fetchItems()
          this.showNotification({
            message: 'NOTY_BLACKLIST_KEYWORD_REMOVED',
            type: 'success',
          })
        } catch (e) {
          this.showNotification({
            message: e,
            type: 'error',
          })
        }
      }
    },
  },
  computed: {
    ...mapState({
      items: state => state.blackLists.keywordBlackList,
    }),
    searchOptions() {
      return {
        ...this.$data.$_paginationMixin_options,
        dateRange: this.dateRange,
      }
    },
  },
}
</script>

<style></style>
